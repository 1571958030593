import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Global/Layout'
import Loader from '../../components/Global/Loader'
import TrademarkSearch from '../../components/TrademarkSearch'
import TrademarkSearchContextProvider from '../../context/TrademarkSearchContextProvider'

const locale = 'en-CA'
export const query = graphql`
  {
    allContentfulTrademarkSearch {
      edges {
        node {
          seo {
            internal {
              content
            }
          }
          input {
            internal {
              content
            }
          }
          searching {
            internal {
              content
            }
          }
          error {
            internal {
              content
            }
          }
          trademarkResults {
            internal {
              content
            }
          }
          trademarkAnalysis {
            internal {
              content
            }
          }
          modal {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }
  }
`

const TrademarkSearchPage = (props) => {
  if (!locale) {
    return (
      <Layout url='/trademark-search' locale={locale}>
        <Loader loading />
      </Layout>
    )
  }

  const data = props.data?.allContentfulTrademarkSearch.edges?.filter(
    (node) => node.node.node_locale === locale
  )[0].node
  const contentful = {
    input: JSON.parse(data.input.internal.content),
    modal: JSON.parse(data.modal.internal.content),
    results: JSON.parse(data.trademarkResults.internal.content),
    trademarkAnalysis: JSON.parse(data.trademarkAnalysis.internal.content),
    searching: JSON.parse(data.searching.internal.content),
    error: JSON.parse(data.error.internal.content)
  }

  return (
    <TrademarkSearchContextProvider>
      <Layout
        locale={locale}
        title={JSON.parse(data?.seo.internal.content).title}
        desc={JSON.parse(data?.seo.internal.content).desc}
        url='/trademark-search'
      >
        <TrademarkSearch contentful={contentful} />
      </Layout>
    </TrademarkSearchContextProvider>
  )
}

export default TrademarkSearchPage
